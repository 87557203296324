/* Advies4.me CSS styles */
/* Fonts import @css = sneller :D */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

body {
  font-family: 'Open Sans', Arial, sans-serif;
}

p {
  line-height: 20px;
}

h1 {
  font-weight: 700;
}

/* voor later wordt het een .header-wrap nu eerst body */
body {
  background: #f1f1f1 url(#{$img-path}/advies4me-koffie-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='.advies4me-koffie-bg.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='advies4me-koffie-bg.jpg', sizingMethod='scale')";
  width: 100%;
  height: auto;
}

#advies4me.logo {
  margin: 30px 0;
}

.advies4me-gradient {
  background: url(#{$img-path}/advies-gr-bg.png);
  height: 375px;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.advies4me-line {
  z-index: 2;
  background: rgba(255, 255, 255, 0.88);
  height: 3px;
  position: relative;
  top: 75px;
}

/* Klote lijn, ik kom er niet helemaal uit hoop jullie wel ;)
anders gebruik je onderstaande code en fuck het lijntje dan maar */

.advies4me-line.midden {
  margin: 0 20px 0 335px;
  width: 610px;
}

.advies4me-line.links {
  float: left;
  margin: 0;
  width: 18%;
}

.advies4me-line.rechts {
  float: right;
  margin: 0;
  width: 17.6%
}

.advies4me-line.door {
  margin: 0 0 0 335px;
}

@media (max-width: 1200px) {
  .advies4me-line.midden {
    margin: 0 0px 0 335px;
    width: 410px;
  }
}

@media (max-width: 992px) {
  .advies4me-line.midden {
    margin: 0 0px 0 335px;
    width: 190px;
  }
}

/**/

/*Alternatief voor het lijntje = */

/*
.advies4me-gradient {height:125px;}
#advies4me.logo {margin:12px 0 50px;}
.advies4me-line {background:none !important;}
*/

.login {
  float: right;
  margin: 50px 15px 10px;
}

.login a.btn {
  border: 3px solid #fff;
  background: none;
  color: #fff;
  text-shadow: none;
  border-radius: 50px;
  font-weight: normal;
  font-size: 14px;
}

.login a.btn:hover {
  background: #48adc1;
}

.advies-op-maat {
  float: left;
  margin: 74px 10px 30px;
  color: #fff;
}

.advies-op-maat h1 {
  font-size: 44px;
}

.advies-op-maat h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 12px 0 24px;
  font-weight: 300;
}

.code {
  width: 320px;
}

.code #trackingcode-input-field {
  border-radius: 40px;
  height: 50px;
  border: none;
  padding: 0 26px;
  font-size: 16px;
}

.code span.input-group-btn {
  position: relative;
  left: -73px;
}

.code span.input-group-btn input#trackingcode-submit-button.btn.btn-success {
  border-radius: 40px;
  height: 40px;
  border: none;
}

.papieren {
  background: #fff url(#{$img-path}/papieren-bg.jpg) left top;
  padding: 40px 50px 60px;
}

.schaduwpapier {
  position: relative;
}

.schaduwpapier:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 5px;
  width: 100%;
  top: 0;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 15px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 15px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 15px 0 rgba(0, 0, 0, 0.26);
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -o-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}

@media (max-width: 768px) {
  #survey-info-page {
    margin: 0px 0px 0px;
  }
}

@media (min-width: 1024px) {
  #survey-info-page {
    margin: 20px 72px 10px;
  }
}

@media (max-width: 768px) {
  #survey-info-page .jumbotron {
    padding-left: 20px;
    padding-right: 20px;
  }
  .papieren {
    padding-top: 40px;
    padding-bottom: 60px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

#scroll-to-bottom {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: -175px;
  width: 175px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
}

#toggle-scroll-to-bottom {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px;
  top: -1px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #FF615F;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: none;
  background-color: #FF6150;
}

#toggle-scroll-to-bottom [class*=fa] {
  font-size: 23px;
  line-height: 50px;
  color: #fff;
}

@media (max-width: 767px) {
  #scroll-to-bottom {
    display: none;
  }
}

#survey-info-page h1 {
  font-size: 28px;
  margin-bottom: 30px;
}

.btn-success {
  border-radius: 50px;
  padding: 8px 14px;
}

.btn-info {
  border-radius: 50px;
  padding: 8px 14px;
}

.sb-head {
  background: #5a5254;
  padding: 10px 15px;
  color: #fff;
}

.sb-head img {
  float: left;
  margin: 4px 10px 0;
}

.sbbg {
  background: #848484;
  padding: 0;
}

.sb-head h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 40px;
}

.vragen {
  float: left;
  width: 100%;
}

.vragen ul {
  padding-left: 0;
}

.vragen ul li {
  list-style: none;
}

.vragen ul li a.list-group-item {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #6e6e6e;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  margin: 0 10px;
  padding: 24px 25px;
}

@media (max-width: 767px) {
  .vragen ul li a.list-group-item {
    color: #414141;
    margin: o;
  }
}

.vragen ul li a.list-group-item.active {
  background: #fff url(#{$img-path}/papieren-bg.jpg) left bottom;
  color: #4e4e4e;
  border-bottom: 3px solid #6e6e6e;
  margin: 0;
}

.vragen ul li a.list-group-item.active.list-group-item-danger.list-group-item-danger {
  border-bottom: 3px solid #ff6150;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

/* .progresshead {background:red;float:left;width:100%;} */
.prbg {
  background: #ececec;
}

footer.foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #686868;
  color: #fff;
  padding: 16px 0 14px;
  font-size: 12px;
}

.foot p {
  margin-bottom: 0;
}

.foot p.fright {
  float: right;
  margin-right: 10px;
}

.progress {
  background: none;
  border-radius: 50px;
  box-shadow: none;
  height: 30px;
  margin: 20px 0;
}

.progress-bar {
  background: #3ba652;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  padding: 4px;
}

.question-group-div h2, #survey-customer-page h2 {
  color: #4e4e4e;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 2px solid #4e4e4e;
  display: inline-block;
  //margin-bottom: 20px;
}

.question-group-div h4 {
  font-weight: 400;
  font-size: 16px;
}

.question .commentList strong {
  font-size: 16px;
}

.commentList {
  //margin: 20px 0 40px;
}

.info-question {
  //margin: 20px 0 40px;
}

.papieren .btn {
  float: right;
}

.question .commentList strong:before {
  font-family: "Glyphicons Halflings";
  content: "";
  color: #ff6150;
  font-size: 12px;
  margin-right: 5px;
  opacity: 0.88;
}

.login a, .code span.input-group-btn input#trackingcode-submit-button.btn.btn-success, .btn {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.flat-schaduw {
  background: rgba(0, 0, 0, 0.1);
  float: left;
  height: 6px;
  margin-top: -6px;
  position: relative;
  width: 100%;
}

.foot-gegevens, .jumbotron {
  background: #37363f;
}

.jumbotron {
  color: #fff;
}

.foot-gegevens h3 {
  border-bottom: 1px solid #767676;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.foot-gegevens ul {
  padding: 0;
}

.foot-gegevens img.icn {
  margin: 0 15px 0 5px;
  float: left;
}

.btn-info, .btn-success {
  padding: 8px 12px;
}

.papieren:before, .papieren:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 0;
  height: 100%;
  background: #ccc;
}

a.list-group-item-danger {
  color: #FF9F9F !important;
}

a.list-group-item-danger::after {
  content: "\f12a";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: #FF9F9F;
  font-size: 18px;
  padding-right: 0.5em;
  position: absolute;
  top: 10px;
  right: 0;
}

#survey-customer-page label {
  padding: 8px;
}
