//
//  =========================================================
//  Imports
//  =========================================================
//

$icon-font-path: "../../../../../assets/fonts/bootstrap/" !default;

@import "bootstrap";

$fa-font-path: "../../../../../assets/fonts" !default;

@import "font-awesome";
@import "_dynatable";

label.ant-radio-wrapper span {
  white-space: normal !important;
}

label.ant-radio-wrapper {
  height: inherit !important;
}

//
//  =========================================================
//  Advies4me
//  Sass Stylesheet
//  =========================================================
//

.vragen ul li a.list-group-item {
  z-index: 2;
}

.container.heading {
  background: whitesmoke;
  padding: 0;
  margin-top: 40px;

  &:before {
    height: 400px !important;
  }

  .head {
    padding: 15px 5px;
    background: whitesmoke;
  }

}

.container.headi
hr > a {
  color: #337ab7
}

hr > a:hover {
  color: #337ab7
}

//
//  =========================================================
//  Advies4me
//  css Stylesheet
//  =========================================================

$img-path: '../../../../../assets/img' !default;

@import "base";

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1 \9
  ; /* IE9 only */
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 1;
    opacity: 1 \9
  ; /* IE9 only */
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1 \9
  ; /* IE9 only */
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: .3s;
  -moz-animation-duration: .3s;
  animation-duration: .3s;

  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.papieren > #advies4me.logo {
  margin-top: 0;
}

//
//  =========================================================
//  Base Variables
//  =========================================================
//

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  left: calc(50% - 6em);
  top: 350px;
  z-index: -1;
  font-size: 10px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* <loader> */

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }
  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* </loader> */

.question-group-div {
  //margin-bottom: 20px;
}

/* The ribbons */

.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 99;
}

/* Custom styles */

.corner-ribbon.sticky {
  position: fixed;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

/* Different positions */

.corner-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right {
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white {
  background: #f0f0f0;
  color: #555;
}

.corner-ribbon.black {
  background: #333;
}

.corner-ribbon.grey {
  background: #999;
}

.corner-ribbon.blue {
  background: #39d;
}

.corner-ribbon.green {
  background: #2c7;
}

.corner-ribbon.turquoise {
  background: #1b9;
}

.corner-ribbon.purple {
  background: #95b;
}

.corner-ribbon.red {
  background: #e43;
}

.corner-ribbon.orange {
  background: #e82;
}

.corner-ribbon.yellow {
  background: #ec0;
}

.advies4me-custom-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,7db9e8+100&amp;1+0,0+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(125, 185, 232, 0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#007db9e8', GradientType=0); /* IE6-9 */
}

.questions .question {
  position:relative;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 8px;
  margin-bottom: 10px;
  
  .question-extended {
    color: grey;
  }
  
}